<template>
  <div class="brands">
    <div class="content_container content">

      <section class="chain-change" style="display: flex;justify-content: center;" v-if="isShowSupply()">
        <div class="change-need-btn change-btn" @click="getSupplyList(2)" :class="tabPosition == 2 ? 'addcolor1' : ''">
          需求</div>
        <div class="change-apply-btn change-btn" @click="getSupplyList(1)" :class="tabPosition == 1 ? 'addcolor2' : ''">
          供应</div>
      </section>
      <main style="display: flex;flex-wrap: wrap;padding: 0 24px;">


        <el-card class="apply-box" v-for="item in supplyData" :key="item.id">
          <img v-lazy="tabPosition == 1 ? applySrc : needSrc" class="apply-sign">

          <section class="apply-header">

            <div style="display: flex;align-items: center;">
              <article :class="tabPosition == 1 ? 'apply-img-box' : 'need-img-box'">
                <img v-if="item.get_member"
                  v-lazy="item.get_member.avatar_url == null ? userSrc : item.get_member.avatar_url">
                <img v-else v-lazy=userSrc>
              </article>

              <article style="margin-left: 20px;">
                <h4 v-if="item.get_member" style="margin-bottom: 10px;">{{ item.get_member.username }}</h4>
                <h4 v-else style="margin-bottom: 10px;">仪商链用户</h4>
                <p style="font-size: 12px;color: #999999;">{{ Math.floor((time1 - item.create_time) / 60 / 60 /
                  24) == 0 ? '刚刚' : Math.floor((time1 - item.create_time) / 60 / 60 / 24) + '天前' }}发布</p>
              </article>
            </div>

            <article style="display: flex;">
              <!-- <div class="apply-phone apply-btn1" style="margin-right: 30px;">电话</div> -->
              <!-- <router-link to="chainChat" target="_blank"> -->
              <!-- <div class="apply-chat apply-btn1"
                @click="privateChat(item.member_id, item.get_member.username, item.get_member.avatar_url)">私聊</div> -->
              <!-- </router-link> -->
            </article>
          </section>
          <div style="height: 96px;">
            <p class="apply-content" @click="fullText(item.content)">
              {{ item.content }}
            </p>
            <p class="apply-orther" v-show="item.orther_illustrate">附加说明：({{ item.orther_illustrate }})</p>
          </div>

          <section class="apply-footer">
            <div style="vertical-align: middle;">
              <span>有效时间：<span>{{ item.end_time == 2102329600 ? '永久' : Math.ceil((item.end_time - item.create_time) / 60 /
                60 / 24) +
                '天'
              }}</span> </span>
            </div>
            <!-- <div style="vertical-align: middle;cursor: pointer;" @click="drawerOpen(item.applySrc)">
              <img style="vertical-align: middle;margin-right: 5px;" src="../../assets/images/chain_chat.png">
              <span>评论</span>
            </div> -->
          </section>

        </el-card>

        <el-card v-if="!supplyData.length" style="text-align: center;width: 100%;margin-bottom: 24px;">暂时没有供需信息哦</el-card>
      </main>


      <el-pagination @current-change="currentChange" :current-page.sync="searchData.page"
        :page-size.sync="searchData.limit" :total="total">
      </el-pagination>
    </div>

  </div>
</template>
<script>


export default {
  data() {
    return {
      //抽屉
      time1: new Date().getTime() / 1000 + 120,
      drawer: false,
      commentForm: {
        write: "",
      },
      //展示供需按钮
      // showSupply: false,
      //供需类型  
      tabPosition: 2,
      state2: '',
      shopList: [],
      total: 0,
      banner: [],
      searchData: {
        shop_type: 2,
        page: 1,
        limit: 15
      },
      //供需数据
      supplyData: [],
      //logo图片路径
      applySrc: require('@/assets/images/chain_apply.png'),
      needSrc: require('@/assets/images/chain_need.png'),
      userSrc: require('@/assets/images/tx.jpg'),
    };
  },
  computed: {
    member() {
      return this.$store.state.member;
    },
    shop() {
      return this.$store.state.shop;
    }
  },
  mounted() {
    // this.getBanner();
    this.currentChange();
    console.log(this.member.shopId, 1111111111)
  },
  methods: {
    //验证是否需要供需按钮
    isShowSupply() {
      if (this.member.shopId) {
        return true
      } else {
        return false
      }
    },

    //聊天
    privateChat(id, username, avatar) {
      let that = this
      if (!this.member.id) {
        this.$alert("请先登录", {
          confirmButtonText: "确定"
        }).then(() => {
          const currentPath = that.$router.currentRoute.fullPath;
          that.$router.replace('/login' + (currentPath && currentPath !== '/' ? '?url=' + encodeURIComponent(currentPath) : ''));
        });
        return
      }
      var avatar1 = this.userSrc
      if (avatar !== undefined) {
        avatar1 = avatar
      }
      this.$get("seller/chatsMessage", { memberId: this.member.id, customId: id }).then(res => {
        console.log(res, 556, avatar1)
        let routeUrl = this.$router.resolve({ path: '/chainChat', query: { customId: id, username: username, avatar: avatar1 } });
        window.open(routeUrl.href, '_blank');
      })
    },

    getSupplyList(tabId) {
      this.tabPosition = tabId || 2
      this.searchData.page = 1
      this.currentChange()
    },

    currentChange() {
      this.supplyData = []
      this.$get("seller/supplyDemand", { type: this.tabPosition, page: this.searchData.page, member_id: this.member.id }).then(res => {
        this.supplyData = res.items.data
        console.log(233, this.supplyData)
        this.total = res.items.total
        console.log(res, 2666)
      });
    },
    fullText(text) {
      var supply1 = this.tabPosition == 1 ? '供应内容' : '需求内容';
      this.$notify({
        title: supply1,
        message: text,
        // duration: 0
      });
    },
  },
};
</script>
<style lang="scss" scoped>
//弹出框

::v-deep .el-drawer {
  width: 500px !important;
}

::v-deep .el-button--primary {
  background: #0054FF;
  padding: 8px 20px;
  border: 0;
  margin-right: 20px;
}

.chain-change {
  margin-bottom: 10px;

  .change-btn {
    cursor: pointer;
    width: 76px;
    height: 28px;
    text-align: center;
    line-height: 26px;
    font-size: 17px;
    transition: all 0.2s;
  }

  .change-need-btn {
    border: 1px solid #0054FF;
    color: #0054FF;
    border-right: 0;
  }

  .change-apply-btn {
    border: 1px solid #E60012;
    border-left: 0;
    color: #E60012;
  }
}

.comment-box1 {
  width: 440px;
  margin: 20px auto;
  display: flex;

  .comment-img-box {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  article {
    h3 {
      color: #777888;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 370px;
    }

    p {
      color: #222226;
      font-size: 14px;
      line-height: 22px;
      word-break: break-word;
      max-width: 370px;
      margin: 5px 0;
      text-align: justify;
    }

    h4 {
      color: #999999;
      font-weight: 400;
      text-align: right;
      width: 370px;
    }
  }
}



::v-deep .el-button--primary:hover {
  background: #1a64fb;
}

::v-deep .el-icon-close:before {
  font-size: 17px;
}

::v-deep .el-drawer__header> :first-child {
  font-size: 17px;
}

::v-deep .el-textarea__inner {
  border: 0;
  background: #F7F8F9;
  resize: none;
  height: 108px;
}

::v-deep ::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::v-deep ::-webkit-scrollbar-thumb {
  background-color: #dbd9d9;
  border-radius: 3px;
}

.addcolor1 {
  background: #0054FF;
  color: #fff !important;
}

.addcolor2 {
  background: #E60012;
  color: #fff !important;
}

.chain-list {
  // margin: 50px 0 20px;
  text-align: center;
}

.apply-box {
  width: 550px;
  position: relative;
  padding: 15px 15px 0;
  margin: 0 13px 30px;
  white-space: pre-wrap;

  .apply-sign {
    position: absolute;
    top: 0;
    left: 0;
    width: 75px;
  }

  .apply-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .apply-img-box {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      line-height: 66px;
      text-align: center;
      overflow: hidden;
      border: 2px solid red;


      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .need-img-box {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      line-height: 66px;
      text-align: center;
      overflow: hidden;
      border: 2px solid #0054FF;


      img {
        max-width: 100%;
        max-height: 100%;
      }
    }


  }

  .apply-content {
    font-size: 16px;
    line-height: 24px;
    margin: 20px 0 4px;
    word-break: break-all;
    text-align: justify; //用在表单中可对齐文本
    letter-spacing: 1px; //字间距
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 72px;
    cursor: pointer;
  }

  .apply-orther {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 4px;
  }

  .apply-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .apply-btn1 {
    width: 54px;
    height: 24px;
    line-height: 23px;
    text-align: center;
    border-radius: 8px;
  }

  .apply-phone {
    border: 1px solid #08B124;
    color: #08B124;

  }

  .apply-chat {
    border: 1px solid #F39B07;
    color: #F39B07;
    cursor: pointer;
  }

  .apply-chat:hover {
    border: 1px solid #ffc96f;
    background: #ffc96f;
    color: #fff;
  }

}
</style>
 